<template>
   <v-container fluid>
       <v-row justify="center">
           <v-col></v-col>
           <v-col cols="12" class="text-center">
               <v-icon size="90">mdi-egg</v-icon>
               <h2>¿Cuántos huevos consume por semana?</h2>
           </v-col>
           <v-col cols="12" sm="12" md="4" class="pb-0">
               <v-text-field
                    v-model="huevos"
                    :error-messages="huevosErrors"
                    label="Consumo de huevos"
                    outlined
                    @input="setHuevos($event)"
                    @blur="$v.huevos.$touch()"
                    inputmode="numeric"
                ></v-text-field>
           </v-col>
           <v-col cols="12" class="text-center">
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q3"
                    >
                    <v-icon left>mdi-chevron-left</v-icon> Regresar
                </v-btn>
               <v-btn
                    class="ma-2 white--text"
                    rounded
                    color="indigo"
                    to="/q5"
                    :disabled="$v.$invalid"
                    >
                    Continuar <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
           </v-col>
       </v-row>
   </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
//eslint-disable-next-line
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    
    mixins: [validationMixin],

    validations: {
      huevos: { required, numeric },
    },

    data() {
        return {
            huevos: ''
        }
    },

    mounted() {
        this.huevos = this.$store.state.huevos
        this.$store.state.progress = 30
        this.goBackIfEmpty()
    },

    computed: {
        huevosErrors () {
            const errors = []
            if (!this.$v.huevos.$dirty) return errors
            !this.$v.huevos.required && errors.push('Consumo de huevos es requerido.')
            !this.$v.huevos.numeric && errors.push('Debe ser un número.')
            return errors
        },
    },

    methods: {
        setHuevos(value) {
            this.$store.state.huevos = value
            this.$v.huevos.$touch()
        },
        goBackIfEmpty(){
           if(!this.$store.state.municipio) {
                this.$router.push('/municipio')
           }else if(!this.$store.state.personas) {
                this.$router.push('/personas')
           }else if(!this.$store.state.cereales) {
                this.$router.push('/q1')
           }else if(!this.$store.state.carnes) {
               this.$router.push('/q2')
           }else if(!this.$store.state.lacteos) {
               this.$router.push('/q3')
           }
       }
    },
}
</script>